import { ReactElement } from 'react';

import Icon, { IconProps } from '@components/atoms/Icon';

const Service = ({ size, ...rest }: IconProps): ReactElement => (
    <Icon size={size} viewBox="0 0 40 40" {...rest}>
        <g>
            <path
                d="M25.9,17.6c2.2,0,4.3,0.9,5.9,2.4l1.5,1.5c1.3-0.5,2.4-1.3,3.4-2.3c2.8-2.8,3.9-6.9,3-10.7C39.5,8,39,7.7,38.5,7.9
		c-0.2,0-0.3,0.1-0.4,0.2l-5.8,5.8L27,13l-0.9-5.3l5.8-5.8c0.4-0.4,0.4-1,0-1.3c-0.1-0.1-0.3-0.2-0.4-0.2c-3.8-1-7.9,0.2-10.7,3
		c-2.1,2.1-3.3,5.1-3.2,8.1l6.4,6.4C24.6,17.7,25.3,17.6,25.9,17.6z"
            />
            <polygon points="17.8,24 17.8,24 17.8,24 	" />
            <path
                d="M17.8,24l-4.4-4.4L1.5,31.5c-1.9,2-1.9,5.2,0.1,7.1c1.9,1.9,5,1.9,6.9,0l9.7-9.7C17.6,27.3,17.4,25.6,17.8,24z M5,36.9
		L5,36.9c-1,0-1.9-0.8-1.9-1.9c0-1,0.8-1.9,1.9-1.9c1,0,1.9,0.8,1.9,1.9C6.9,36,6,36.9,5,36.9z"
            />
            <path
                d="M30,21.8c-1.8-1.8-4.4-2.2-6.7-1.1L15,12.4V7.5L5,0L0,5l7.5,10h4.8l8.3,8.3c-1.1,2.2-0.7,4.9,1.1,6.7l9.1,9.1
		c1.1,1.1,3,1.1,4.1,0c0,0,0,0,0,0l4.1-4.1c1.1-1.1,1.1-3,0-4.1L30,21.8z"
            />
        </g>
    </Icon>
);

export default Service;
